/* eslint-disable max-classes-per-file */
// @flow
import axios, { type AxiosPromise, type AxiosXHRConfig, type $AxiosXHR } from 'axios';
import queryString from 'query-string';
import { signOutAction } from 'domain/env/envActions';
import { TokenKeeper } from './apiTokenKeeper';
import Cookies from 'js-cookie';

const methods = {
  post: 'post',
  get: 'get',
  put: 'put',
  delete: 'delete',
};

export const CONFIGURABLE_API_ENDPOINT = Cookies.get(process.env.REACT_APP_API_ENDPOINT_COOKIE_NAME) || process.env.REACT_APP_API_URL;

const triesKey = 'dokka-app-reload-tries';

type MethodsType = $Keys<typeof methods>;

type ParamsType = {
  +[key: string]: string | number,
};

type UrlArgumentsType<T> = [Array<string>, Array<$Keys<T>>];

type MakeUrlType<T: ParamsType> = (...arg: UrlArgumentsType<T>) => (args: T) => string;

type ArgsType<T> = {
  ...AxiosXHRConfig<T>,
  ...ParamsType,
};

export function makeUrl<T: ParamsType>(strings: Array<string>, ...values: Array<$Keys<T>>) {
  return (args: T): string =>
    values.reduceRight((a, v, i) => `${strings[i]}${args[v]}${a}`, strings[strings.length - 1]);
}

export const getResponseInterceptors = (store) => {
  const successHandler = (response) => response;

  const errorHandler = (error) => {
    // requests failing in worker dont have access to window
    if (typeof window !== 'undefined') {
      const isItMaintenance = window.location.pathname === '/maintenance';

      if (error.response && error.response.status === 426) {
        const triesFromLS = window.localStorage.getItem(triesKey) || 0;
        if (triesFromLS < 4) {
          window.localStorage.setItem(triesKey, parseFloat(triesFromLS) + 1);
          window.location.reload(true);
        } else {
          window.localStorage.removeItem(triesKey);
          store.dispatch({ type: signOutAction.type });
        }
      }

      if (error.response && error.response.status === 427 && !isItMaintenance) {
        window.location = `${window.location.origin}/maintenance`;
      }

      const { REACT_APP_ACCESS_DENIED_CODE } = process.env;
      if (error.response && error.response.status.toString() === REACT_APP_ACCESS_DENIED_CODE) {
        store.dispatch({
          type: signOutAction.type,
          payload: {
            reason: REACT_APP_ACCESS_DENIED_CODE,
          },
        });
      }
    }
    return Promise.reject(error);
  };

  return [successHandler, errorHandler];
};

function urlHelper(baseUrl: string) {
  return (uri: string | MakeUrlType<ParamsType>) => {
    if (typeof uri === 'string') {
      return () => `${baseUrl}${uri}`;
    }
    if (typeof uri === 'function') {
      return (args: ParamsType): string => `${baseUrl}${uri(args)}`;
    }
    throw new Error('Incorrect uri parametr');
  };
}

export function makeRequest<T>(method: MethodsType, url: (a: ParamsType) => string, tokenKeeper: TokenKeeper = null) {
  return async (args: ArgsType<T>): AxiosPromise<T> => {
    const params = {
      method,
      // timeout: 5000, // not enabled for now
      url: url(args),
      ...args,
      params: Object.assign(args.params || {}, {
        'x-dokka-app-version': process.env.REACT_APP_BUILD_VERSION,
        'x-dokka-app-env': process.env.REACT_APP_ENV,
      }),
      headers: {
        ...args.headers,
      },
    };

    const requestData = tokenKeeper ? await tokenKeeper.updateRequestData(params) : params;
    return axios(requestData);
  };
}

export function requestCreator(baseUrl: string, tokenKeeper: ?TokenKeeper = null) {
  const url = urlHelper(baseUrl);
  return Object.keys(methods).reduce(
    (a: {}, v: MethodsType) => ({
      ...a,
      [v]: (uri: string | MakeUrlType<ParamsType>) => makeRequest(methods[v], url(uri), tokenKeeper),
    }),
    {},
  );
}

export type RequestGeneratorType<T> = (p: Object) => Generator<*, $AxiosXHR<T>, *>;
export type RequestPromiseType<T> = (p: Object) => AxiosPromise<T>;
type MockPromiseType<T> = () => Promise<{ data: T }>;
type Validator<T> = (d: T) => T;
type LoggerType = (e: TypeError, m: Object | Array<Object>) => void;

type URLMakerType = (params: Object) => string;
type UriType = string | URLMakerType;

export class MakeRequest<T> {
  // eslint-disable-next-line max-len
  constructor(
    baseUrl: string,
    method: MethodsType,
    uri: UriType,
    validator: (d: T) => T,
    loger: LoggerType,
    tokenKeeper: TokenKeeper,
  ) {
    this._baseUrl = baseUrl;
    this._method = method;
    this._uri = uri;
    this._validator = validator;
    this._loger = loger;
    this._tokenKeeper = tokenKeeper;
  }

  _baseUrl: string;

  _uri: UriType;

  _method: MethodsType;

  _validator: (d: T) => T;

  _loger: LoggerType;

  _tokenKeeper: TokenKeeper;

  url(params: Object): string {
    if (typeof this._uri === 'string') {
      return this._baseUrl + this._uri;
    }
    return this._baseUrl + this._uri(params);
  }

  static params(params?: Object = {}) {
    return {
      'x-dokka-app-version': process.env.REACT_APP_BUILD_VERSION,
      'x-dokka-app-env': process.env.REACT_APP_ENV,
      ...params,
    };
  }

  async config({ params, ...args }: AxiosXHRConfig<T>): AxiosXHRConfig<T> {
    const res = {
      method: this._method,
      url: this.url(args),
      params: this.constructor.params(params),
      ...args,
    };

    return this._tokenKeeper.updateRequestData(res);
  }

  async request(params: Object, responseModifier: ?(resp: $AxiosXHR<T>) => $AxiosXHR<T>): AxiosPromise<T> {
    return new Promise(async (resolve, reject) => {
      const config = await this.config(params || {});
      axios(config)
        .then((resp) => {
          try {
            this._validator(resp.data);
          } catch (err) {
            this._loger(err, resp);
            // TODO: add tag to logger if validation error
          }
          const handler = typeof responseModifier === 'function' ? responseModifier : (x) => x;
          return resolve(handler(resp));
        })
        .catch((err) => {
          console.log('ERROR', err);
          reject(err);
        });
    });
  }
}

export class RequestCreator {
  constructor(baseUrl: string, loger: LoggerType, tokenKeeper: TokenKeeper) {
    this._baseUrl = baseUrl;
    this._loger = loger;
    this._tokenKeeper = tokenKeeper;
  }

  _baseUrl: string;

  _loger: LoggerType;

  _tokenKeeper: TokenKeeper;

  // eslint-disable-next-line max-len
  _request<T>(
    method: MethodsType,
    uri: UriType,
    validator: Validator<T>,
    responseModifier: ?(resp: $AxiosXHR<T>) => $AxiosXHR<T> = null,
  ): (p: Object) => AxiosPromise<T> {
    const instance: MakeRequest<T> = new MakeRequest(
      this._baseUrl,
      method,
      uri,
      validator,
      this._loger,
      this._tokenKeeper,
    );
    return (p: Object) => instance.request(p, responseModifier);
  }

  get<T>(
    uri: UriType,
    validator: Validator<T>,
    responseModifier: ?(resp: $AxiosXHR<T>) => $AxiosXHR<T>,
  ): RequestPromiseType<T> {
    return this._request('get', uri, validator, responseModifier);
  }

  post<T>(uri: UriType, validator: Validator<T>): RequestPromiseType<T> {
    return this._request('post', uri, validator);
  }

  put<T>(uri: UriType, validator: Validator<T>): RequestPromiseType<T> {
    return this._request('put', uri, validator);
  }

  delete<T>(uri: UriType, validator: Validator<T>): RequestPromiseType<T> {
    return this._request('delete', uri, validator);
  }

  url(uri: string): (q: Object) => string {
    return (query: Object) => `${this._baseUrl + uri}?${queryString.stringify(query, { arrayFormat: 'bracket' })}`;
  }

  mock<T>(response: T | (() => T), validator: Validator<T>): MockPromiseType<T> {
    return (...args) =>
      Promise.resolve(typeof response === 'function' ? response(...args) : response).then((resp) => {
        try {
          validator(resp);
        } catch (err) {
          // $FlowFixMe
          this._loger(err, resp);
        }
        // make compatible with axios requests that wrap response with 'data' key
        return { data: resp };
      });
  }
}

/* @flow */
import { List, Record, Set, Map, fromJS, type RecordOf, type RecordFactory } from 'immutable';

import {
  isPolygonInArea,
  dataAdapter,
  includesAdapter,
  JournalFactory,
  MetaFactory,
  matchCell,
  jeGridLineColumnsAdapter,
} from './helper';

import type { JournalEntryType } from 'domain/contracts';
import type {
  JournalEntryMessageType,
  RowMessage,
  TIndexListRaw,
  TIndexListItemRaw,
  TIndexListItemRecord,
  TIndexListItemRecords,
} from './types.js.flow';
import { type TSelectOption } from 'pages/common/input/select';
import { textractForVendorEnabledSelector } from './selectors';

type CellType = {|
  xStart: number,
  xEnd: number,
  yStart: number,
  yEnd: number,
|};

type PolygonsType = {|
  top: number,
  left: number,
  width: number,
  height: number,
  text: string,
  id: string,
|};

type StartingCellType = {|
  name: string,
  id: string,
|};

type StructureType = List<List<RecordOf<CellType>>>;

export function matchCellParams(cell: string): [string, number] {
  const res = cell.match(/^(\D+)(\d+)$/);
  if (res && res.length === 3) {
    return [res[1], parseInt(res[2], 10)];
  }
  throw new Error(`Cell not match pattern ${cell}`);
}

export function matchNamePrams(name: string): [number, number, string] {
  const parts = name.split('_');
  if (parts.length > 3) {
    const params = parts.slice(-2);
    const prefix = parts.slice(0, -2).join('_');
    return [parseInt(params[0], 10), parseInt(params[1], 10), prefix];
  }
  throw new TypeError('Imcorrect name of cell');
}

export function getNextChar(c: string, shift: number = 1): string {
  const charCode = c.charCodeAt(c.lenght - 1);
  if (charCode < 65 || charCode > 90) throw new Error('Invalid simbol');
  return String.fromCharCode(charCode + shift);
}

function stringCellFactory(value: string | number, name: string) {
  return {
    return: true,
    set: { value },
    name,
    readonly: false,
    type: 'string',
    bold: false,
    hint: null,
    mandatory: false,
  };
}

function match(gridCell: CellType, polygon: PolygonsType): boolean {
  const { xStart, xEnd, yStart, yEnd } = gridCell;
  return isPolygonInArea(
    { x: xStart, y: yStart, width: xEnd - xStart, height: yEnd - yStart }, // Selected area
    polygon, // Polygon
  );
}

function matchCellToString(gridCell: CellType, polygons: $ReadOnlyArray<PolygonsType>): string {
  const isMatch = (p: PolygonsType) => match(gridCell, p);
  return polygons.reduce((a, v) => (isMatch(v) ? a.concat(v.text) : a), []).join(' ');
}

function gridRowAdapter(
  data: List<RecordOf<CellType>>,
  row: number,
  startColumn: string,
  matchToString: (c: CellType) => string,
  nameCreator: (c: number) => string,
) {
  return data.reduce(
    (a, v, i) => ({
      ...a,
      [`${getNextChar(startColumn, i)}${row}`]: stringCellFactory(matchToString(v), nameCreator(i)),
    }),
    {},
  );
}

export function gridAdapter(grid: StructureType, start: StartingCellType, polygons: $ReadOnlyArray<PolygonsType>) {
  const [startColumn, startRow] = matchCellParams(start.id);
  const [nameRow, nameColumn, namePrefix] = matchNamePrams(start.name);
  const createName = (r: number, sc: number) => (c: number) => [namePrefix, r, sc + c].join('_');
  const matcher = (cell: CellType) => matchCellToString(cell, polygons);
  const rowAdapter = (data: List<RecordOf<CellType>>, row: number, nameCreator: Function) =>
    gridRowAdapter(data, row, startColumn, matcher, nameCreator);

  return grid.reduce(
    (a, v, i) => ({
      ...a,
      ...rowAdapter(v, i + startRow, createName(nameRow + i, nameColumn)),
    }),
    {},
  );
}

export const messageFactory: RecordFactory<JournalEntryMessageType> = new Record({
  level: 'info',
  text: '',
  type: '',
});

const rowMessageFactory: RecordFactory<RowMessage> = new Record({
  row: 0,
  message: '',
  type: 'info',
});

// eslint-disable-next-line max-len
export function messagesAdapter(
  messages: $ReadOnlyArray<$ReadOnly<JournalEntryMessageType>>,
): List<RecordOf<JournalEntryMessageType>> {
  return messages.reduce((a, msg) => a.push(messageFactory(msg)), new List());
}

// eslint-disable-next-line max-len
export function rowMessagesAdapter(messages: $ReadOnlyArray<$ReadOnly<RowMessage>>): List<RecordOf<RowMessage>> {
  return messages.reduce((a, msg) => a.push(rowMessageFactory(msg)), new List());
}

const lineItemDataFactory = new Record({
  line_id: '',
  line_request_status: null,
});

function lineItemsDataAdapter(data) {
  return Object.entries(data).reduce((a, v) => a.set(v[0], lineItemDataFactory(v[1])), new Map());
}

export function JEAdapter(rawData: JournalEntryType) {
  const {
    entries,
    includes,
    line_items, // eslint-disable-line camelcase
    header_lines_number, // eslint-disable-line camelcase
    header_settings, // eslint-disable-line camelcase
    lines,
    summary,
    row_messages, // eslint-disable-line camelcase
    initial_hidden_columns, // eslint-disable-line camelcase
    messages,
    publicationWarnings,
    currentPage,
    pageCount,
    reconcile_column, // eslint-disable-line camelcase
    pinned_column, // eslint-disable-line camelcase
    amount_column, // eslint-disable-line camelcase
    balance_column, // eslint-disable-line camelcase
    txn_id_column, // eslint-disable-line camelcase
    transaction_type_column, // eslint-disable-line camelcase
    date_column, // eslint-disable-line camelcase
    description_column, // eslint-disable-line camelcase
    not_hidden_columns, // eslint-disable-line camelcase
    error_from_erp, // eslint-disable-line camelcase
    line_items_data, // eslint-disable-line camelcase
    two_way_match, // eslint-disable-line camelcase
    matchedLines,
    ...data
  }: JournalEntryType = rawData;

  return JournalFactory({
    ...data,
    matchedLines: fromJS(matchedLines),
    messages: messagesAdapter(messages),
    publicationWarnings: Set(publicationWarnings),
    entry: dataAdapter(entries),
    includes: includesAdapter(includes),
    line_items: Set(line_items || []), // eslint-disable-line camelcase
    settings: Set(header_settings || []), // eslint-disable-line camelcase
    lines: Set(lines || []), // eslint-disable-line camelcase
    headerLinesNumber: header_lines_number, // eslint-disable-line camelcase
    // eslint-disable-next-line max-len
    rowMessages: rowMessagesAdapter(row_messages || []), // eslint-disable-line camelcase
    summary: Set(summary || []), // eslint-disable-line camelcase
    initial_hidden_columns: List(initial_hidden_columns || []), // eslint-disable-line camelcase
    reconcileColumn: reconcile_column, // eslint-disable-line camelcase
    pinnedColumn: List(pinned_column || []), // eslint-disable-line camelcase
    amountColumn: amount_column, // eslint-disable-line camelcase
    balanceColumn: balance_column, // eslint-disable-line camelcase
    transactionIdColumn: txn_id_column, // eslint-disable-line camelcase
    dateColumn: date_column, // eslint-disable-line camelcase
    descriptionColumn: description_column, // eslint-disable-line camelcase
    transactionTypeColumn: transaction_type_column, // eslint-disable-line camelcase
    errorFromErp: error_from_erp, // eslint-disable-line camelcase
    notHiddenColumns: Set(not_hidden_columns || []), // eslint-disable-line camelcase
    meta: MetaFactory({
      currentPage,
      pageCount,
    }),
    // eslint-disable-next-line camelcase
    lineItemsData: lineItemsDataAdapter(line_items_data || {}),
    jeGridLineColumnsDictionary: jeGridLineColumnsAdapter(entries),
    textractForVendorEnabled: two_way_match,
  });
}

export function itemAdapter(item, selectedRows: Set<number> = new Set(), value?: string) {
  const { _cell, _options, _row, _col, cellSet, valid, ...rest } = item.toJS();
  const [colName, rowNumber] = matchCell(_cell);
  const rowNumbersUpdate = selectedRows.has(rowNumber) ? selectedRows : Set([rowNumber]);
  
  const data =
    typeof cellSet !== 'undefined'
      ? {
          ...rest,
          set: {
            ...cellSet,
            value: typeof value === 'undefined' ? cellSet.value : value,
          },
        }
      : rest;

  return rowNumbersUpdate.reduce((res, row) => {
    const cellName = `${colName}${row}`;
    return { ...res, [cellName]: data };
  }, {});
}

export function bulkCommit(items: Set<number>, col: string) {
  return items.reduce(
    (a, v) => ({
      ...a,
      [`${col}${v}`]: {
        set: {
          value: 'Commit',
        },
      },
    }),
    {},
  );
}

// JE INDEX LIST
// ///////////////////
export const IndexListItemOptionFactory: RecordFactory<TSelectOption> = new Record({
  value: '',
  label: '',
});

export const indexListItemOptionAdapter = (key: string) => IndexListItemOptionFactory({ value: key, label: key });

const IndexListItemFactory: TIndexListItemRecord = new Record({
  label: '',
  mandatory: false,
  name: '',
  type: '',
  value: null,
  options: List(),
});

export const indexListAdapter = (data: TIndexListRaw): TIndexListItemRecords =>
  data.reduce((acc: TIndexListItemRecords, item: TIndexListItemRaw) => {
    const itemRecord = IndexListItemFactory(item);

    if (item.type === 'list') {
      const itemRecordWithOptions = itemRecord.set(
        'options',
        List(Object.keys(item.options)).map((itemKey: string) => indexListItemOptionAdapter(itemKey)),
      );
      return acc.push(itemRecordWithOptions);
    }

    return acc.push(itemRecord);
  }, List());

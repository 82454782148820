// @flow
import React, { Component } from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// actions/selectors
import {
  activeReferenceSelector,
  referenceSelector,
  setRefernceAction,
  setLoadingAction,
  endLoadingAction,
  setEditingAction,
  endEditingAction,
} from 'domain/journal';

// actions/selectors
import {
  addTransactionLineAction,
  removeTransactionLineAction,
  updateSearchParamsAction,
  reconcileTransactionAction,
  undoTransactionAction,
  reconcileSelectedJournalSelector as reconcileSelectedResultsSelector,
  reconcileSelectedJournalCounterSelector,
  reconcileJournalEntriesSelector,
  reconcileSearchResultsSelector,
  reconcileSearchParamsSelector,
  reconcileSearchResultsCountSelector,
  reconcileSearchResultsTotalSelector,
  reconcileRowStateSelector,
  lineItemsNumbersSelector,
  transactionAmountSelector,
  selectedTransactionsAmountSelector,
  anotherTransactionMatchedLinesSelector,
  transactionErrorSelector,
  isReconciliationRestrictedSelector,
  transactionDateSelector,
  transactionDescriptionSelector,
  loadinglSelector,
} from 'domain/reconciliation';

import { rtlEnable, documentViewSelector } from 'domain/env';

// components
import Popup from 'components/Popup';
import Cell from 'components/Tables/cell';
import DescriptionCell from 'components/Tables/description';
import { Settings as SearchForm, SearchResults, SelectedResults } from 'components/Tables/layout';
import Title from '../ReconcileTitle';
import TransactionDetails from '../ReconcileTransactionDetails';
import ResultsCount from '../ReconcileResultsCount';
import Spinner from 'components/Spinner';

// immutable
import { List, Map, Record, Set } from 'immutable';

// types
import type { JornalEntryType, RecordsCellType, RecordCellSetType } from 'domain/journal/helper';
import type { ReferenceRecord, ReconcileCellValueType, RowMessage } from 'domain/journal/types.js.flow';

// helpers
import { matchCell } from 'domain/journal/helper';
import { promisify } from 'lib/helpers';

// jss
import cx from 'classnames';
import { withStyles } from '@mui/styles';
import sheet from './sheet';
import tableSheet from 'components/Tables/sheet';

// intl
import { FormattedMessage } from 'react-intl';

// transaction states
import { TRANSACTION_STATES } from 'components/Tables/ReconciliationCell/constants';
import elements from 'components/elements';

type Props = {
  loading: boolean,
  classes: { [key: string]: string },
  data: JornalEntryType,
  gridData: JornalEntryType,
  settingsData: JornalEntryType,
  selectedResults: JornalEntryType,
  reconcileRowState: ReconcileCellValueType,
  resultsCount: number,
  selectedResultsCount: number,
  resultsTotal: number,
  resultsRowsNumbers: Set<number>,
  anotherTransactionMatchedLines: List<RowMessage>,
  activeReference: ?ReferenceRecord,
  onChange: typeof updateSearchParamsAction,
  reconcileTransaction: typeof reconcileTransactionAction,
  addTransactionLine: typeof addTransactionLineAction,
  removeTransactionLine: typeof removeTransactionLineAction,
  undoTransaction: typeof undoTransactionAction,
  setActive: (p: { id: string, params: ReferenceRecord }) => void,
  rtl: boolean,
  documentID: string,
  documentView: Map<'documentsView' | 'infoBarWidth', number>,
  onClose: () => void,
  transactionAmount: number,
  transactionDate: string,
  transactionDescription: string,
  selectedTransactionsAmount: number,
  transactionError: ?string,
  isReconciliationRestricted: boolean,
  pageParams: {| page: number, pageSize: number |},
};

type State = {|
  active: ?string,
  selectedRows: Set<number>,
  autoselectionApplied: boolean,
  actionInProgress: boolean,
|};

const intlPrefix = 'reconciliation.transactionDetails';
const intlSummaryPrefix = `${intlPrefix}.summary`;
const messageId = (positive: boolean) =>
  positive ? 'reconciliation.transactionDetails.positive' : 'reconciliation.transactionDetails.negative';
const defaultMessageId = (positive: boolean) => (positive ? 'Received' : 'Spent');

class Reconsile extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    // Autoselect rows only once when resultsRows is not empty (data loaded).
    // Later selectedRows can be set by user actions
    if (
      Reconsile.resultsAutoselectedMode(props) &&
      props.resultsRowsNumbers.size !== 0 &&
      !state.autoselectionApplied
    ) {
      return {
        selectedRows: props.resultsRowsNumbers,
        autoselectionApplied: true,
      };
    }
    return null;
  }

  static resultsAutoselectedMode = (props: Props): boolean => {
    switch (props.reconcileRowState) {
      case TRANSACTION_STATES.RECONCILED:
      case TRANSACTION_STATES.MATCH:
        return true;
      default:
        return false;
    }
  };

  state: State = {
    active: null,
    selectedRows: Set(),
    autoselectionApplied: false,
    actionInProgress: false,
  };

  getItem = (data: RecordsCellType | void) => {
    const { classes, setEditing, endEditing, isJEEditing, reference, documentView, rtl, activeReference, setActive } = this.props;
    const { selectedRows, active } = this.state;
    if (data && data instanceof Record) {
      if (data.type === 'description') {
        return <DescriptionCell classes={classes} data={data} />;
      }
      const cell = matchCell(data._cell);

      return (
        <Cell
          data={data}
          key={data.type + data.readonly}
          rtl={rtl}
          isPriorityErp={false}
          createRefernce={() => {
            console.log('createRefernce');
          }}
          activeReference={activeReference}
          setActiveReference={setActive}
          reference={reference}
          polygons={Map()}
          optionList={List()}
          active={active}
          classes={classes}
          onChange={this.handleChange}
          onActive={this.handleActive}
          documentView={documentView}
          isSupport={false}
          insertGrid={() => {
            console.log('insertGrid cell method invoked');
          }}
          setActiveCell={this.setActiveCell}
          isBulkEdit={selectedRows.has(cell[1])}
          gridData={Map()} // required for recomciliation only, unused in this table
          amountColumn="H" // required for recomciliation only, unused in this table
          setEditing={setEditing}
          endEditing={endEditing}
          isJEEditing={isJEEditing}
        />
      );
    }
    return null;
  };

  getUpdateParams(cell: string, cellSet: RecordCellSetType): RecordsCellType | void {
    const item: RecordsCellType | void = this.props.data.get(cell);
    if (item instanceof Record) {
      return item.set('cellSet', cellSet);
    }
    return undefined;
  }

  get readOnlyMode(): boolean {
    return this.props.reconcileRowState === TRANSACTION_STATES.RECONCILED;
  }

  get hasSelectedRows(): boolean {
    return this.state.selectedRows.size > 0;
  }

  get actionButton(): React$Node {
    const { reconcileRowState, isReconciliationRestricted, classes } = this.props;
    const { actionInProgress } = this.state;
    const intlStatePrefix = 'reconciliation.transactionDetails.action';
    switch (reconcileRowState) {
      case TRANSACTION_STATES.RECONCILED:
        return (
          <React.Fragment>
            <button
              data-element={elements.je.reconcileSearch.btn.undo}
              className={cx(classes.actionUndoButton, { [classes.actionInProgress]: actionInProgress })}
              onClick={this.undoTransaction}
            >
              {!actionInProgress && <FormattedMessage id={`${intlStatePrefix}.UNDO`} defaultMessage="Undo" />}
            </button>
            <button
              data-element={elements.je.reconcileSearch.btn.ok}
              className={classes.actionButton}
              onClick={this.close}
            >
              <FormattedMessage id={`${intlStatePrefix}.${TRANSACTION_STATES.RECONCILED}`} defaultMessage="Ok" />
            </button>
          </React.Fragment>
        );
      case TRANSACTION_STATES.MATCH:
        return (
          <button
            data-element={elements.je.reconcileSearch.btn.match}
            className={cx(classes.actionButton, { [classes.actionInProgress]: actionInProgress })}
            onClick={this.reconcileTransaction}
            disabled={isReconciliationRestricted}
          >
            {!actionInProgress && (
              <FormattedMessage id={`${intlStatePrefix}.${TRANSACTION_STATES.MATCH}`} defaultMessage="Match" />
            )}
          </button>
        );
      default:
        return (
          <button
            data-element={elements.je.reconcileSearch.btn.save}
            className={cx(classes.actionButton, { [classes.actionInProgress]: actionInProgress })}
            onClick={this.reconcileTransaction}
            disabled={isReconciliationRestricted}
          >
            {!actionInProgress && <FormattedMessage id={`${intlStatePrefix}.DEFAULT`} defaultMessage="Save" />}
          </button>
        );
    }
  }

  get transactionAmount(): number {
    return this.props.transactionAmount;
  }

  get positiveTransactionAmount(): number {
    return Math.abs(this.transactionAmount);
  }

  get isPositive(): boolean {
    return this.transactionAmount >= 0;
  }

  // transaction and selected transactions difference rounded to X.XX format
  get difference(): number {
    // @see https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
    return (
      Math.round((this.positiveTransactionAmount - this.props.selectedTransactionsAmount) * 100 + Number.EPSILON) / 100
    );
  }

  setActiveCell = (cellId: string | null): void => {
    const { active } = this.state;
    if (cellId !== active) {
      this.setState({ active: cellId });
    }
  };

  selectRows = (selectedRows: Set<number>) => {
    this.setState({ selectedRows });
  };

  addSelectedRows = (): void => {
    const { selectedRows } = this.state;
    const { addTransactionLine } = this.props;
    addTransactionLine(selectedRows);
  };

  handleChange = (cell: string, cellSet: RecordCellSetType): void => {
    const { documentID, onChange } = this.props;
    const item = this.getUpdateParams(cell, cellSet);
    if (item) {
      this.setState(
        // clear selected lines before update is sent as selection is not valid
        // after results updated
        { selectedRows: Set() },
        () => onChange({ item, documentID }),
      );
    }
  };

  handleActive = (value: string) => {
    if (typeof value === 'string') {
      this.setActiveCell(value);
    }
  };

  removeResultHandler = (row: number) => {
    this.props.removeTransactionLine(row);
  };

  reconcileActionFactory = (action) => () => {
    const { pageParams } = this.props;
    this.setState({ actionInProgress: true });
    return promisify(action, { pageParams })
      .then(() => {
        this.setState({ actionInProgress: false });
        // close popup. Success toast will be triggered in saga
        this.close();
      })
      .catch(() => {
        this.setState({ actionInProgress: false });
        // dont close popup as it must have error displayed in it
      });
  };

  reconcileTransaction = this.reconcileActionFactory(this.props.reconcileTransaction);
  undoTransaction = this.reconcileActionFactory(this.props.undoTransaction);

  close = () => this.props.onClose();

  render() {
    const {
      classes,
      loading,
      settingsData,
      gridData,
      resultsCount,
      selectedResultsCount,
      resultsTotal,
      selectedResults,
      onClose,
      selectedTransactionsAmount,
      anotherTransactionMatchedLines,
      reconcileRowState,
      transactionError,
      transactionDate,
      transactionDescription,
    } = this.props;
    const { active, selectedRows } = this.state;

    return (
      <Popup disableMove cancel={onClose} close className={cx(classes.container)}>
        {loading && <Spinner loading={loading} className={classes.loader} />}
        <section className={classes.tableHeader} id="popupWrapper">
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <h1 className={classes.title}>
                <Title state={reconcileRowState} positive={this.transactionAmount >= 0} />
              </h1>
              <TransactionDetails date={transactionDate} description={transactionDescription} />
            </div>
            <div className={classes.headerRight}>

                <section className={classes.summaryDetails}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <FormattedMessage
                            id={messageId(this.isPositive)}
                            defaultMessage={defaultMessageId(this.isPositive)}
                          />
                          :
                        </td>
                        <td data-element={elements.je.reconcileSearch.spent}>{this.positiveTransactionAmount}</td>
                      </tr>
                      {!selectedResults.isEmpty() && (
                        <>
                          <tr>
                            <td>
                              <FormattedMessage
                                id={`${intlSummaryPrefix}.selectedTransactions`}
                                defaultMessage="Selected"
                              />
                              :
                            </td>
                            <td data-element={elements.je.reconcileSearch.selected}>{selectedTransactionsAmount}</td>
                          </tr>
                          <tr>
                            <td>
                              <FormattedMessage id={`${intlSummaryPrefix}.difference`} defaultMessage="Difference" />:
                            </td>
                            <td data-element={elements.je.reconcileSearch.diff}>{this.difference}</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </section>

            </div>
          </div>
          <SearchForm getItem={this.getItem} active={active} data={settingsData} />
        </section>
        <section
          className={cx(classes.tableWrapper, classes.scrollAlwaysVisible, {
            [classes.noResults]: resultsCount === 0,
          })}
          id="tableWrapper"
        >
          <>
            {reconcileRowState !== TRANSACTION_STATES.RECONCILED && resultsCount === resultsTotal && (
              <ResultsCount className={classes.counter} count={resultsCount} total={resultsTotal} />
            )}
            <SearchResults
              getItem={this.getItem}
              data={gridData}
              selectedRows={selectedRows}
              disableCheckboxes={this.readOnlyMode}
              selectPlaceCol="A"
              startRow={1}
              readOnly={false}
              onChangeSelectedRows={this.selectRows}
              rowMessages={anotherTransactionMatchedLines}
            />
            {!resultsCount && (
              <div className={classes.emptyResults}>
                <FormattedMessage
                  id={`${intlPrefix}.emptyResults`}
                  defaultMessage="Nothing found\n Try checking search params to get corresponding search results"
                />
              </div>
            )}
            {resultsCount > 0 && (
              <div
                className={cx(classes.addRowButtonContainer, {
                  [classes.withWarning]: resultsCount !== resultsTotal,
                })}
              >
                {resultsCount !== resultsTotal && (
                  <div className={classes.resultsCounterNotification}>
                    <FormattedMessage
                      id={`${intlPrefix}.results.counterNotification`}
                      defaultMessage="Your search returned too many results. Please, be more specific"
                    />
                  </div>
                )}
                <button
                  data-element={elements.je.reconcileSearch.btn.add}
                  disabled={!this.hasSelectedRows || this.readOnlyMode}
                  className={classes.addRowButton}
                  onClick={this.addSelectedRows}
                >
                  <FormattedMessage id={`${intlPrefix}.results.addButton`} defaultMessage="Add" />
                </button>
              </div>
            )}
          </>
        </section>
        <section className={cx(classes.selectedSearchResultsWrapper, classes.scrollAlwaysVisible)}>
          {selectedResultsCount > 0 && (
            <>
              <div className={classes.summaryHeader}>Summary</div>
              <SelectedResults
                getItem={this.getItem}
                data={selectedResults}
                removeHandler={this.removeResultHandler}
                allowLineDeletion={!this.readOnlyMode}
              />
            </>
          )}
        </section>
        <section className={classes.footer}>
          {transactionError && <div className={classes.transactionError}>{transactionError}</div>}
          {this.actionButton}
        </section>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => ({
  activeReference: activeReferenceSelector(state),
  reference: referenceSelector(state),
  data: reconcileJournalEntriesSelector(state),
  gridData: reconcileSearchResultsSelector(state),
  selectedResults: reconcileSelectedResultsSelector(state),
  selectedResultsCount: reconcileSelectedJournalCounterSelector(state),
  settingsData: reconcileSearchParamsSelector(state),
  resultsCount: reconcileSearchResultsCountSelector(state),
  resultsTotal: reconcileSearchResultsTotalSelector(state),
  reconcileRowState: reconcileRowStateSelector(state),
  resultsRowsNumbers: lineItemsNumbersSelector(state),
  documentView: documentViewSelector(state),
  transactionAmount: transactionAmountSelector(state),
  transactionDate: transactionDateSelector(state),
  transactionDescription: transactionDescriptionSelector(state),
  selectedTransactionsAmount: selectedTransactionsAmountSelector(state),
  anotherTransactionMatchedLines: anotherTransactionMatchedLinesSelector(state),
  transactionError: transactionErrorSelector(state),
  isReconciliationRestricted: isReconciliationRestrictedSelector(state),
  loading: loadinglSelector(state),
  rtl: rtlEnable(state),
});

const mapDispatchToProps = {
  onChange: updateSearchParamsAction,
  setActive: setRefernceAction,
  addTransactionLine: addTransactionLineAction,
  removeTransactionLine: removeTransactionLineAction,
  reconcileTransaction: reconcileTransactionAction,
  undoTransaction: undoTransactionAction,
  setLoading: setLoadingAction,
  endLoading: endLoadingAction,
  setEditing: setEditingAction,
  endEditing: endEditingAction,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles((theme) => ({ ...tableSheet(theme), ...sheet(theme) })),
)(Reconsile);
